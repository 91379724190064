import React, { useContext, useState, useEffect, useMemo } from "react";
import axios from "../../utils/axiosHelper";
import { format } from "date-fns";
import { GlobalData } from "../../context/globalData";
import { addSpace } from "../../utils/spaceSeprator";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  addLeadingZeroToDateRange,
  datePayload,
} from "../../utils/moduleTabApi";
import InvestmentProfileTemplate from "./InvestmentProfileTemplate";
import { Card, Grid } from "@material-ui/core";
import CardDetail from "../../components/Header/cardDetail";
import teamMemberImg from "../../assets/users.png";
import check from "../../assets/check.png";
import { config } from "../../Config/permission";
import InvestmentFilter from "../../components/investmentProfileFilter/InvestmentFilter";
import { Box } from "@mui/material";
import CheckSelect from "../../components/select/CheckSelect";

const InvestmentProfile = () => {
  const [issueType, setIssueType] = useState();
  const { projectRangePickerValue } = useContext(GlobalData);
  const { projectId: project } = useParams();
  const [issueList, setIssueList] = useState();
  const [pieGraphData, setpieGraphData] = useState();
  const [issueData, setIssueData] = useState();
  const [issueColumn, setIssueColumn] = useState();
  const [date, setDate] = useState(projectRangePickerValue);
  const [trendDate, setTrendDate] = useState(projectRangePickerValue);
  const [stackChartData, setStackChartData] = useState([]);
  const [iteration, setIteration] = useState();
  const [selectedAssignee, setSelectedAssignee] = useState("All");
  const [HeaderData, setHeaderData] = useState({});
  const [graphType, setGraphType] = useState("workday");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [issueCostHandler, setIssueCostHandler] = React.useState(null);
  const [selectedViewBy, setSelectedViewBy] = useState();
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const [costAmt, setCostAmt] = useState("");
  const [issueCostAmt, setIssueCostAmt] = useState("");
  const [issueLevelCost, setIssueLevelCost] = useState();
  const [TrendUnit, setTrendUnit] = useState("Day's");
  const [TrendLabel, setTrendLabel] = useState("Time (Day's)");
  const [AllTeamList, setAllTeamList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [Cumulative, setCumulative] = useState("");
  const [totalCostInDoller, setTotalCostInDoller] = useState("");
  const [selectedTeamOrResourceUrl, setSelectedTeamOrResourceUrl] =
    useState("");

  const viewType = useMemo(() => {
    const type = selectedViewBy?.includes("Cost") ? "Cost" : selectedViewBy;
    return type;
  }, [selectedViewBy]);

  useEffect(() => {
    if (selectedTeam?.length > 0) {
      let url = " ";
      selectedTeam.forEach((e, index) => {
        if (index === 0) {
          url += `&teamName=${e}`;
        } else {
          url += `&teamName=${e}`;
        }
      });
      setSelectedTeamOrResourceUrl(url.trim(), selectedTeam);
    } else {
      setSelectedTeamOrResourceUrl("", selectedTeam);
    }
  }, [selectedTeam]);

  useEffect(() => {
    let url = `/api/v2/teams/list/kpis?no_of_days=60&startDay=2024-04-1&endDay=2024-05-31&iteration=7&project_id=${project}`;
    axios
      .get(url)
      .then((response) => {
        const list = response?.data.map((item) => {
          return item?.TeamName;
        });
        setAllTeamList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const projectDetailsHandler = () => {
    if (!project) return;
    const dateUrl = datePayload(date);
    const zeroDate = addLeadingZeroToDateRange(dateUrl);
    let url = `/api/v2/investmentProfile/details/${project}?${zeroDate}`;
    if (selectedTeamOrResourceUrl) {
      url = `/api/v2/investmentProfile/resourceLevelDetails?projectId=${project}&${zeroDate}${selectedTeamOrResourceUrl}`;
    }
    if (viewType) {
      url += `&type=${viewType}`;
    }
    if (viewType === "Cost") {
      url += `&cost=${costAmt}`;
    }
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((result) => {
        setHeaderData({
          TaskCount: result?.data?.TaskCount,
          ResourceCount: result?.data?.ResourceCount,
          StartDate: format(new Date(result?.data?.StartDate), "dd-MM-yyyy"),
          EndDate: format(new Date(result?.data?.EndDate), "dd-MM-yyyy"),
        });
        let issue = result?.data?.IssueType?.map((item) => item?.IssueType);
        setIssueList(issue);
        let pieGraphData = result?.data?.res?.map((item) => {
          return {
            title: item?.title,
            value: item?.value.toFixed(),
          };
        });
        setpieGraphData(pieGraphData);
        setIssueType(result?.data?.IssueType?.[0]?.IssueType);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const staclchartHandler = () => {
    if (!project) return;
    const dateUrl = datePayload(date);
    const zeroDate = addLeadingZeroToDateRange(dateUrl);
    let url;
    if (epicData?.url) {
      url = `/api/v2/investmentProfile/monthtrend/multipleTeam/${project}${epicData?.url}&iteration=${iteration}&level=Resource&${zeroDate}&selectType=${graphType}`;
    } else {
      url = `/api/v2/investmentProfile/monthtrend/multipleTeam/${project}?iteration=${iteration}&level=Resource&${zeroDate}&selectType=${graphType}`;
    }
    if (viewType) {
      url += `&type=${viewType}`;
    }
    if (viewType === "Cost") {
      url += `&cost=${costAmt}`;
    }

    if (selectedTeamOrResourceUrl) {
      url += selectedTeamOrResourceUrl;
    }

    axios
      .get(url)
      .then((result) => {
        const AllTeamItem = result?.data?.map((e) => {
          return {
            SprintName: e?.category || "N/A",
            Team:
              e?.Team?.map((team) => {
                return {
                  TeamName: team?.TeamName || "Not Assigned",
                  Story: team?.Story || null,
                  Bug: team?.Bug || null,
                  Task: team?.Task || null,
                  Improvement: team?.Improvement || null,
                  "Sub Task": team["Sub-task"] || team["Subtask"] || null,
                  "New Feature":
                    team["New Feature"] || team["NewFeature"] || null,
                  "Dev Sub Task": team["Dev Sub Task"] || null,
                  Req: team?.Req || null,
                  "Internal CR/Enhancement":
                    team["Internal CR/Enhancement"] || null,
                  Essential: team?.Essential || null,
                  Query: team?.Query || null,
                };
              }) || [],
          };
        });
        setStackChartData(AllTeamItem);
        // window.scrollTo(0, 520);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const issueDetailsHandler = () => {
    if (!project && !issueType) return;
    const dateUrl = datePayload(date);
    let url;
    if (issueType === "All") {
      if (epicData?.url) {
        url = `/api/v2/investmentProfile/issuelevel/${project}${epicData?.url}&${dateUrl}`;
      } else {
        url = `/api/v2/investmentProfile/issuelevel/${project}?${dateUrl}`;
      }
    } else {
      if (epicData?.url) {
        url = `/api/v2/investmentProfile/issuelevel/${project}${epicData?.url}&issuetype=${issueType}&${dateUrl}&resourceName=${selectedAssignee}`;
      } else {
        url = `/api/v2/investmentProfile/issuelevel/${project}?issuetype=${issueType}&${dateUrl}&resourceName=${selectedAssignee}`;
      }
    }
    if (selectedTeamOrResourceUrl) {
      url += selectedTeamOrResourceUrl;
    }
    if (issueLevelCost) {
      url += `&cost=${issueLevelCost}`;
    }

    axios
      .get(url)
      .then((result) => {
        setIssueData(result?.data?.result2);
        setCumulative(result?.data?.totalFTE);
        const totalCost = result?.data?.result2.reduce((acc, curr) => {
          return acc + curr?.Cost;
        }, 0);
        setTotalCostInDoller(totalCost);
        const data = Object.keys(
          result.data.result2.length > 0 ? result?.data?.result2?.[0] : {}
        )?.filter((e) => {
          if (e !== "tableData" && e !== "TaskLink") {
            return e;
          }
        });
        const columns = data?.map((e) => {
          return {
            field: e,
            title: addSpace(e),
            render: (data) => {
              if (e === "TaskId") {
                return (
                  <a target="_blank" href={data["TaskLink"]} rel="noreferrer">
                    {data?.TaskId}
                  </a>
                );
              } else if (e === "CreatedTime") {
                return format(new Date(data[e]), "dd-MMM-yyy");
              } else {
                return data[e];
              }
            },
          };
        });
        setIssueColumn(columns);
        // window.scrollTo(0, document.body.scrollHeight);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    projectDetailsHandler();
  }, [
    project,
    selectedViewBy,
    viewType,
    epicData,
    date,
    selectedTeamOrResourceUrl,
  ]);
  useEffect(() => {
    if (iteration) {
      staclchartHandler();
    }
  }, [
    iteration,
    trendDate,
    graphType,
    viewType,
    epicData,
    date,
    selectedTeamOrResourceUrl,
  ]);

  useEffect(() => {
    issueDetailsHandler();
  }, [
    issueType,
    date,
    selectedAssignee,
    epicData?.url,
    selectedTeamOrResourceUrl,
    issueLevelCost,
  ]);

  // Header Cost
  const handleConst = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCostClose = () => {
    setAnchorEl(null);
  };

  const ApplyHandler = () => {
    setSelectedViewBy(`Cost${Math.random()}`);
    handleCostClose();
  };

  // Issue level Cost
  const handleIssueConst = (event) => {
    setIssueCostHandler(event.currentTarget);
  };

  const handleIssueClose = () => {
    setIssueCostHandler(null);
  };

  const ApplyIssueHandler = () => {
    setIssueLevelCost(issueCostAmt);
    handleIssueClose();
  };

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url.trim(), selectedEpic: list });
  };

  useEffect(() => {
    switch (viewType) {
      case "Percent":
        setTrendUnit("%");
        setTrendLabel("Time (%)");
        break;
      case "FTE":
        setTrendUnit("FTE");
        setTrendLabel("FTE");
        break;
      case "Cost":
        setTrendUnit("$");
        setTrendLabel("Cost ($)");
        break;
      default:
        break;
    }
  }, [viewType]);

  return (
    <>
      <Box mb={2}>
        <InvestmentFilter
          setDate={setDate}
          date={date}
          issueType={issueType}
          setIssueType={setIssueType}
          anchorEl={anchorEl}
          handleClose={handleCostClose}
          handleClick={handleConst}
          ApplyHandler={ApplyHandler}
          costAmt={costAmt}
          setCostAmt={setCostAmt}
          epicDropDownHandler={epicDropDownHandler}
          selectedViewBy={viewType}
          setSelectedViewBy={setSelectedViewBy}
          HeaderData={HeaderData}
          projectId={project}
        />
      </Box>
      <Grid container spacing={2} style={{ marginBottom: "20px" }}>
        <Grid item xs={3}>
          <Card className={`customCard`}>
            <CardDetail
              img={check}
              value={`${HeaderData?.TaskCount || ""}`}
              name={`No. of ${config.type} completed`}
            />
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={`customCard`}>
            <CardDetail
              img={teamMemberImg}
              value={`${HeaderData?.ResourceCount || ""}`}
              name={"Total Resource"}
            />
          </Card>
        </Grid>
        <Grid item xs={3}>
          <div className="mb-3">Please select Team:</div>
          <CheckSelect
            names={AllTeamList}
            setValue={(value) => {
              setSelectedTeam(value);
            }}
            value={selectedTeam}
          />
        </Grid>
      </Grid>
      <InvestmentProfileTemplate
        setIssueType={setIssueType}
        issueList={issueList}
        pieGraphData={pieGraphData}
        issueData={issueData}
        issueColumn={issueColumn}
        issueType={issueType}
        // date={date} for hide date from issue level
        setDate={setDate}
        // StackedColumnData={stackChartData}
        IsAllTeamTrend={stackChartData}
        StackedColumnFlag={true}
        iteration={iteration}
        setIteration={setIteration}
        setTrendDate={setTrendDate}
        // trendDate={trendDate} for hide date from trend
        // project={project}
        selectedAssignee={selectedAssignee}
        setSelectedAssignee={setSelectedAssignee}
        graphType={graphType}
        setGraphType={setGraphType}
        TrendUnit={TrendUnit}
        trendLabel={TrendLabel}
        isCumulative={Cumulative}
        showCost={handleIssueConst}
        issueCostHandler={issueCostHandler}
        handleIssueCostClose={handleIssueClose}
        setIssueCostAmt={setIssueCostAmt}
        issueCostAmt={issueCostAmt}
        ApplyIssueHandler={ApplyIssueHandler}
        totalCostInDoller={totalCostInDoller}
      />
    </>
  );
};

export default InvestmentProfile;
