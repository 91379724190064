import React, { useRef } from "react";
import StackedColumnChart from "../../../../components/stackedColumnChart/StackedColumnChart";
import { useContext } from "react";
import { GlobalData } from "../../../../context/globalData";
import {
  addLeadingZeroToDateRange,
  datePayload,
  getSelectedDate,
} from "../../../../utils/moduleTabApi";
import { useState, useEffect } from "react";
import axios from "../../../../utils/axiosHelper";
import RangeSelector from "../../../../components/rangeSelector";
import { Button, Card } from "@material-ui/core";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import NormalSelect from "../../../../components/select/normalSelect";
import SelectField from "../../../../components/select/selectField";
import LineTrend from "../../../../components/lineTrend";
import LineChart from "../../../../components/lineTrend";
import TrendBar from "./TrendBar";
import { config } from "../../../../Config/permission";

function Trend({
  issueType,
  projectId,
  teamId,
  teamName,
  resourceName,
  epic,
  job,
  selectedTeam,
}) {
  const { projectRangePickerValue } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [data, setData] = useState([]);
  const [iteration, setIteration] = useState(30);
  const [openRangeTrend, setOpenRangeTrand] = useState(false);
  const myDivRef = useRef(null);
  useEffect(() => {
    // if (!iteration || !date) return;
    const dateUrl = datePayload(date);
    const leadZero = addLeadingZeroToDateRange(dateUrl);
    if (issueType === "Planning Accuracy") {
      let url = `/api/v2/projects/planningaccuracy/trends/${projectId}?iteration=${iteration}&${dateUrl}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Work Allocation") {
      let url = `/api/v2/projects/resourceallocation/trends/${projectId}?iteration=${iteration}&${dateUrl}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === `${teamName} Team Work Allocation`) {
      let url = `/api/v2/projects/resourceallocation/trends/${projectId}?iteration=${iteration}&${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Cycle Time Breakdown") {
      let trendUrl = `/api/v2/projects/cycleTime/trends/${projectId}?iteration=${iteration}&${dateUrl}`;
      let epicUrl = `/api/v2/projects/cycleTime/trends/${projectId}${epic}&iteration=${iteration}&${dateUrl}`;
      const url = epic ? epicUrl : trendUrl;
      api(url);
    } else if (issueType === "First Time pass issue") {
      let url = `/api/v2/projects/firstTimePassIssue/trends/${projectId}?iteration=${iteration}&${dateUrl}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Commit Without Ticket Reference") {
      let url = `/api/v2/projects/commitwoticketrefTrend/${projectId}?iteration=${iteration}&${leadZero}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Commit Without Ticket Reference") {
      let url = `/api/v2/projects/commitwoticketrefTrend/${projectId}?iteration=${iteration}&${leadZero}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Resource Commit Without Ticket Reference") {
      let url = `/api/v2/projects/commitwoticketrefTrend/${projectId}?iteration=${iteration}&${leadZero}&resourceName=${resourceName}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Code Churn") {
      api(
        `/api/v2/teams/codechurnTrend/${projectId}?iteration=${iteration}&${dateUrl}&teamId=${teamId}`
      );
    } else if (issueType === "Resource Code Churn") {
      api(
        `/api/v2/teams/codechurnTrend/${projectId}?iteration=${iteration}&${dateUrl}&resourceName=${resourceName}`
      );
    } else if (issueType === "Commit with pull request") {
      let url = `/api/v2/projects/commitwithpullreqTrend/${projectId}?iteration=${iteration}&${dateUrl}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Commit with pull request") {
      let url = `/api/v2/projects/commitwithpullreqTrend/${projectId}?iteration=${iteration}&${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Resource Commit with pull request") {
      api(
        `/api/v2/projects/commitwithpullreqTrend/${projectId}?iteration=${iteration}&${dateUrl}&resourceName=${resourceName}`
      );
    } else if (issueType === "Cycle Time (Pull Request)") {
      let url = `/api/v2/teams/cycleTimePR/trend/${projectId}?${dateUrl}&iteration=${iteration}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Cycle Time (Pull Request)") {
      let url = `/api/v2/teams/cycleTimePR/trend/${projectId}?${dateUrl}&iteration=${iteration}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    }
    // else if (issueType === "WIP Average Age") {
    //   api(
    //     `/api/v2/projects/avgAgingTrend/${projectId}?iteration=${iteration}&${dateUrl}`
    //   );
    // } else if (issueType === "Team WIP Average Age") {
    //   api(
    //     `/api/v2/projects/avgAgingTrend/${projectId}?iteration=${iteration}&${dateUrl}&teamId=${teamId}`
    //   );
    // } else if (issueType === "Resource WIP Average Age") {
    //   api(
    //     `/api/v2/projects/avgAgingTrend/${projectId}?iteration=${iteration}&${dateUrl}&resourceName=${resourceName}`
    //   );
    // }
    else if (issueType === "Work Breakdown") {
      let trendUrl = `/api/v2/projects/workBreakdownTrend/${projectId}?iteration=${iteration}&${dateUrl}`;
      let epicUrl = `/api/v2/projects/workBreakdownTrend/${projectId}${epic}&iteration=${iteration}&${dateUrl}`;
      const url = epic ? epicUrl : trendUrl;
      api(url);
    } else if (issueType === "Team Work Breakdown") {
      let url = `/api/v2/projects/workBreakdownTrend/${projectId}?iteration=${iteration}&${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Resource Work Breakdown") {
      let url = `/api/v2/projects/workBreakdownTrend/${projectId}?iteration=${iteration}&${dateUrl}&resourceName=${resourceName}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Average Flow Efficiency") {
      let url = `/api/v2/projects/flowEfficiencytrend/${projectId}?iteration=${iteration}&${leadZero}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Average Flow Efficiency") {
      let url = `/api/v2/projects/flowEfficiencytrend/${projectId}?iteration=${iteration}&${leadZero}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Resource Average Flow Efficiency") {
      let url = `/api/v2/projects/flowEfficiencytrend/${projectId}?iteration=${iteration}&${leadZero}&resourceName=${resourceName}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Unreviewed PR") {
      let url = `/api/v2/projects/prReviewUnreviewTrend/${projectId}?iteration=${iteration}&${dateUrl}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Unreviewed PR") {
      let url = `/api/v2/projects/prReviewUnreviewTrend/${projectId}?iteration=${iteration}&${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Resource Unreviewed PR") {
      let url = `/api/v2/projects/prReviewUnreviewTrend/${projectId}?iteration=${iteration}&${dateUrl}&resourceName=${resourceName}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Pr Review Time") {
      let url = `/api/v2/projects/prReviewTimeTrend/${projectId}?iteration=${iteration}&${dateUrl}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Pr Review Time") {
      let url = `/api/v2/projects/prReviewTimeTrend/${projectId}?iteration=${iteration}&${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Commit without pull request") {
      let url = `/api/v2/projects/commitwithoutpullreqTrend/${projectId}?iteration=${iteration}&${dateUrl}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Commit without pull request") {
      let url = `/api/v2/projects/commitwithoutpullreqTrend/${projectId}?iteration=${iteration}&${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Resource Commit without pull request") {
      let url = `/api/v2/projects/commitwithoutpullreqTrend/${projectId}?iteration=${iteration}&${dateUrl}&resourceName=${resourceName}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Speeding Transitions Rate") {
      let url = `/api/v2/projects/speedyTransitionTrend?projectId=${projectId}&${dateUrl}&iterations=${iteration}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Speeding Transitions Rate") {
      let url = `/api/v2/projects/speedyTransitionTrend?projectId=${projectId}&${dateUrl}&iterations=${iteration}&teamName=${teamName}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Resource Speeding Transitions Rate") {
      let url = `/api/v2/projects/speedyTransitionTrend?projectId=${projectId}&${dateUrl}&iterations=${iteration}&resourceName=${resourceName}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Dev Attention Time") {
      let url = `/api/v2/resource/devattentionTrends/${projectId}?${dateUrl}&iteration=${iteration}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Dev Attention Time") {
      let url = `/api/v2/resource/devattentionTrends/${projectId}?${dateUrl}&teamName=${teamName}&iteration=${iteration}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Resource Dev Attention Time") {
      let url = `/api/v2/resource/devattentionTrends/${projectId}?${dateUrl}&resourceName=${resourceName}&iteration=${iteration}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (
      issueType === "Total time spent on interruptions" ||
      issueType === "Total time spent on fragments" ||
      issueType === "Total time spent on meeting" ||
      issueType === "Total time spent on deep work"
    ) {
      api(
        `/api/v2/resource/engagementTrends?${dateUrl}&iteration=${iteration}`
      );
    } else if (issueType === "Mean Time To Restore") {
      let url = `/api/v2/projects/doraMatricTrend/${projectId}?iteration=${iteration}&${dateUrl}&Metric=MeanTimeToRestore`;
      if (job) {
        url += `&Job=${job}`;
      }
      if (selectedTeam) {
        url += `&teamId=${selectedTeam}`;
      }
      api(url);
    } else if (issueType === "Deployment Frequency") {
      let url = `/api/v2/projects/doraMatricTrend/${projectId}?iteration=${iteration}&${dateUrl}&Metric=DeploymentFrequency
`;
      if (job) {
        url += `&Job=${job}`;
      }
      if (selectedTeam) {
        url += `&teamId=${selectedTeam}`;
      }
      api(url);
    } else if (issueType === "Change failure rate") {
      let url = `/api/v2/projects/doraMatricTrend/${projectId}?iteration=${iteration}&${dateUrl}&Metric=FailureRate`;
      if (job) {
        url += `&Job=${job}`;
      }
      if (selectedTeam) {
        url += `&teamId=${selectedTeam}`;
      }
      api(url);
    } else if (issueType === "Mean Lead Time for Change") {
      let url = `/api/v2/projects/doraMatricTrend/${projectId}?iteration=${iteration}&${dateUrl}&Metric=MeanLeadTime`;
      if (job) {
        url += `&Job=${job}`;
      }
      if (selectedTeam) {
        url += `&teamId=${selectedTeam}`;
      }
      api(url);
    } else if (issueType === "Ticket Without Estimation") {
      let url = `/api/v2/projects/ticketWithoutEstimationTrends?${dateUrl}&projectId=${projectId}&iteration=${iteration}`;
      api(url);
    } else if (issueType === "Team Ticket Without Estimation") {
      let url = `/api/v2/projects/ticketWithoutEstimationTrends?${dateUrl}&projectId=${projectId}&iteration=${iteration}&teamId=${teamId}`;
      api(url);
    } else if (issueType === "Resource Ticket Without Estimation") {
      let url = `/api/v2/projects/ticketWithoutEstimationTrends?${dateUrl}&projectId=${projectId}&iteration=${iteration}&resourceName=${resourceName}`;
      api(url);
    } else if (issueType === "Ticket Without Epics/Stories") {
      let url = `/api/v2/projects/trend/ticketWithoutEpicTrend/?projectId=${projectId}&${dateUrl}&iteration=${iteration}`;
      api(url);
    } else if (issueType === "Team Ticket Without Epics/Stories") {
      let url = `/api/v2/projects/trend/ticketWithoutEpicTrend/?projectId=${projectId}&${dateUrl}&iteration=${iteration}&teamId=${teamId}`;
      api(url);
    } else if (issueType === "Resource Ticket Without Epics/Stories") {
      let url = `/api/v2/projects/trend/ticketWithoutEpicTrend/?projectId=${projectId}&${dateUrl}&iteration=${iteration}&teamId=${teamId}&resourceName=${resourceName}`;
      api(url);
    } else if (
      issueType === "Team Deployment Frequency" ||
      issueType === "Team Change failure rate" ||
      issueType === "Team Deployment rollback" ||
      issueType === "Mean Lead Time for Change"
    ) {
      api(
        `/api/v2/projects/doraMatricTrend/${projectId}?iteration=${iteration}&${dateUrl}&teamId=${teamId}`
      );
    }
  }, [iteration, date, issueType, epic, job, selectedTeam]);
  const scrollToDiv = () => {
    if (myDivRef.current) {
      myDivRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const api = (url) => {
    axios(url)
      .then((res) => {
        if (issueType === "Planning Accuracy") {
          const data = res.data.map((e) => {
            return {
              category: e.StartDate,
              Done: e.DonePer,
              "In Progress": e.InProgress,
              "On Hold": e.OnHold,
              // Done: e.DonePer,
              // "In Progress": e.InProgress,
              // "On Hold": e.OnHold,
            };
          });
          setData(data);
        } else if (issueType === "Work Allocation") {
          const result = [];
          res.data.forEach((ele) => {
            const data = {
              category: ele._id,
            };
            ele.data.forEach((item) => {
              if (!item.UserName) return;
              data[item.UserName] = item.Task;
            });
            result.push(data);
          });
          setData(result);
        } else if (issueType === `${teamName} Team Work Allocation`) {
          const result = [];
          res.data.forEach((ele) => {
            const data = {
              category: ele._id,
            };
            ele.data.forEach((item) => {
              if (!item.UserName) return;
              data[item.UserName] = item.Task;
            });
            result.push(data);
          });
          setData(result);
        } else if (issueType === "Cycle Time Breakdown") {
          const CycleBreakD = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "Spec Closure Time": e?.SpecClosureTime,
              "Planning Cycle Time": e?.PlanningCycleTime,
              "Design Time": e?.DesignTime,
              Open: e?.Open,
              Staging: e?.Staging,
              Backlog: e?.Backlog,
              "In Progress": e?.InProgress,
              "QA Completed": e?.QACompleted,
              "Ready For Testing": e?.ReadyForTesting,
              "Code Review": e?.CodeReview,
            };
          });
          setData(CycleBreakD);
        } else if (issueType === "First Time pass issue") {
          const firstTimeIssue = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "First Time": e?.FirstTime.toFixed(),
              "Return Rate": e?.ReturnRate.toFixed(),
            };
          });
          setData(firstTimeIssue);
        } else if (
          issueType === "Commit Without Ticket Reference" ||
          issueType === "Resource Commit Without Ticket Reference" ||
          issueType === "Team Commit Without Ticket Reference"
        ) {
          const CommitWithoutTicketReference = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "Commit Without Ticket Reference": e?.CommitwoTicket.toFixed(),
            };
          });
          setData(CommitWithoutTicketReference);
        } else if (
          issueType === "Commit with pull request" ||
          issueType === "Resource Commit with pull request" ||
          issueType === "Team Commit with pull request"
        ) {
          const CommitWithoutTicketReference = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "Commit with pull request": e?.commitwithprnumber.toFixed(),
            };
          });
          setData(CommitWithoutTicketReference);
        }
        // else if (
        //   issueType === "WIP Average Age" ||
        //   issueType === "Team WIP Average Age"
        // ) {
        //   const CommitWithoutTicketReference = res?.data?.map((e) => {
        //     return {
        //       category: e?.StartDate,
        //       "Work In Progress": e?.Time.toFixed(),
        //       // StoryPoints: e?.StoryPoints.toFixed(),
        //     };
        //   });
        //   setData(CommitWithoutTicketReference);
        // }
        else if (
          issueType === "Work Breakdown" ||
          issueType === "Team Work Breakdown" ||
          issueType === "Resource Work Breakdown"
        ) {
          const CommitWithoutTicketReference = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "New Work": e?.newWork,
              Refactor: e?.refactor,
              "Re-work": e?.reWork,
              "Help Others": e?.HelpOthers,
            };
          });
          setData(CommitWithoutTicketReference);
        } else if (
          issueType === "Commit without pull request" ||
          issueType === "Team Commit without pull request" ||
          issueType === "Resource Commit without pull request"
        ) {
          const CommitWithoutTicketReference = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "Commit Without Pr": e?.commitwithprPercent,
            };
          });
          setData(CommitWithoutTicketReference);
        } else if (
          issueType === "Average Flow Efficiency" ||
          issueType === "Team Average Flow Efficiency" ||
          issueType === "Resource Average Flow Efficiency"
        ) {
          const CommitWithoutTicketReference = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              Efficiency: e?.Efficiency.toFixed(),
            };
          });
          setData(CommitWithoutTicketReference);
        } else if (
          issueType === "Unreviewed PR" ||
          issueType === "Team Unreviewed PR" ||
          issueType === "Resource Unreviewed PR"
        ) {
          const CommitWithoutTicketReference = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "Unreviewed PR": e?.UnreviewedPR.toFixed(),
            };
          });
          setData(CommitWithoutTicketReference);
        } else if (
          issueType === "Pr Review Time" ||
          issueType === "Team Pr Review Time"
        ) {
          const CommitWithoutTicketReference = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "Pr Review Time": e?.reviewTime.toFixed(2),
            };
          });
          setData(CommitWithoutTicketReference);
        } else if (
          issueType === "Team Code Churn" ||
          issueType === "Resource Code Churn"
        ) {
          const CommitWithoutTicketReference = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "Code Churn": e?.codechurn,
            };
          });
          setData(CommitWithoutTicketReference);
        } else if (
          issueType === "Deployment rollback" ||
          issueType === "Team Deployment rollback"
        ) {
          const CommitWithoutTicketReference = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "Deployment Rollback": e?.deploymentRollback,
            };
          });
          setData(CommitWithoutTicketReference);
        } else if (issueType === "Team Deployment Frequency") {
          const CommitWithoutTicketReference = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "Deployment Frequency": e?.deploymentFrequency,
            };
          });
          setData(CommitWithoutTicketReference);
        } else if (issueType === "Deployment Frequency") {
          const CommitWithoutTicketReference = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              // "Deployment Frequency": e?.deploymentFrequency,
              Success: e?.success,
              Failure: e?.failure,
            };
          });
          setData(CommitWithoutTicketReference);
        } else if (
          issueType === "Change failure rate" ||
          issueType === "Team Change failure rate"
        ) {
          const CommitWithoutTicketReference = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "Change failure rate": e?.failurePercentage,
            };
          });
          setData(CommitWithoutTicketReference);
        } else if (issueType === "Mean Lead Time for Change") {
          const CommitWithoutTicketReference = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "Mean Lead Time for Change": e?.meanLeadTimeChange,
            };
          });
          setData(CommitWithoutTicketReference);
        } else if (issueType === "Mean Time To Restore") {
          const CommitWithoutTicketReference = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "Mean Time To Restore": e?.meanTimeToRestore,
            };
          });
          setData(CommitWithoutTicketReference);
        } else if (
          issueType === "Cycle Time (Pull Request)" ||
          issueType === "Team Cycle Time (Pull Request)"
        ) {
          const cycleTimePR = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              Coding: e?.Coding?.toFixed(),
              // "Coding Time": e?.CodingTime,
              Deploy: e?.Deploy?.toFixed(),
              // "Deploy Time": e?.DeployTime,
              Pickup: e?.Pickup?.toFixed(),
              // "Pickup Time": e?.PickupTime,
              Review: e?.Review?.toFixed(),
              // "Review Time": e?.Review,
              "Idle Time": e?.Idle?.toFixed(),
            };
          });
          setData(cycleTimePR);
        } else if (issueType === "Total time spent on interruptions") {
          const cycleTimePR = res?.data?.trends?.map((e) => {
            return {
              category: e?.date,
              Interruptions: e?.Interruptions,
            };
          });
          setData(cycleTimePR);
        } else if (issueType === "Total time spent on fragments") {
          const cycleTimePR = res?.data?.trends?.map((e) => {
            return {
              category: e?.date,
              Fragment: e?.Fragment,
            };
          });
          setData(cycleTimePR);
        } else if (issueType === "Total time spent on meeting") {
          const cycleTimePR = res?.data?.trends?.map((e) => {
            return {
              category: e?.date,
              Meetings: e?.Meetings,
            };
          });
          setData(cycleTimePR);
        } else if (
          issueType === "Ticket Without Epics/Stories" ||
          issueType === "Team Ticket Without Epics/Stories" ||
          issueType === "Resource Ticket Without Epics/Stories"
        ) {
          const cycleTimePR = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              Count: e?.Count,
            };
          });
          setData(cycleTimePR);
        } else if (issueType === "Speeding Transitions Rate") {
          const cycleTimePR = res?.data?.trends?.map((e) => {
            return {
              category: e?.startDate,
              Percent: e?.percent.toFixed(),
            };
          });
          setData(cycleTimePR);
        } else if (issueType === "Team Speeding Transitions Rate") {
          const cycleTimePR = res?.data?.trends?.map((e) => {
            return {
              category: e?.startDate,
              Percent: e?.percent.toFixed(),
            };
          });
          setData(cycleTimePR);
        } else if (issueType === "Resource Speeding Transitions Rate") {
          const cycleTimePR = res?.data?.trends?.map((e) => {
            return {
              category: e?.startDate,
              Percent: e?.percent.toFixed(),
            };
          });
          setData(cycleTimePR);
        } else if (issueType === "Total time spent on deep work") {
          const cycleTimePR = res?.data?.trends?.map((e) => {
            return {
              category: e?.date,
              DeepWork: e?.DeepWork,
            };
          });
          setData(cycleTimePR);
        } else if (
          issueType === "Team Dev Attention Time" ||
          issueType === "Resource Dev Attention Time" ||
          issueType === "Dev Attention Time"
        ) {
          const cycleTimePR = res?.data?.allTrends?.map((e) => {
            return {
              category: e?.category,
              "Total Time Elapsed": e?.totalTimeElapsed,
            };
          });
          setData(cycleTimePR);
        } else if (
          issueType === "Team Ticket Without Estimation" ||
          issueType === "Resource Ticket Without Estimation" ||
          issueType === "Ticket Without Estimation"
        ) {
          const cycleTimePR = res?.data?.map((e) => {
            return {
              category: e?.StartDate,
              Count: e?.Count,
            };
          });
          setData(cycleTimePR);
        }
        scrollToDiv();
      })
      .catch((error) => {
        setData([]);
        console.log(error);
      });
  };
  const yAxesLabelText = (issueType) => {
    switch (issueType) {
      case "First Time pass issue":
        return `${config?.type} (Count)`;
      case "Cycle Time Breakdown":
        return `${config?.type} (Days)`;
      case "Commit with pull request":
      case "Resource Commit with pull request":
      case "Team Commit with pull request":
        return "Commit with pull request (%)";
      case "Commit Without Ticket Reference":
      case "Resource Commit Without Ticket Reference":
      case "Team Commit Without Ticket Reference":
        return "Commit Without Ticket Reference (%)";
      // case "WIP Average Age":
      // case "Team WIP Average Age":
      //   return "Work In Progress (Day's)";
      case "Average Flow Efficiency":
      case "Team Average Flow Efficiency":
      case "Resource Average Flow Efficiency":
        return "Average Flow Efficiency (%)";
      case "Pr Review Time":
      case "Team Pr Review Time":
        return "Pr Review Time (Day's)";
      case "Unreviewed PR":
      case "Team Unreviewed PR":
      case "Resource Unreviewed PR":
        return "Unreviewed PR (Count)";
      case "Work Breakdown":
      case "Team Work Breakdown":
      case "Resource Work Breakdown":
        return "Work Breakdown (%)";
      case "Team Code Churn":
      case "Resource Code Churn":
        return "Code Churn (%)";
      case "Deployment Frequency":
      case "Team Deployment Frequency":
        return "Deployment Frequency (Count)";
      case "Change failure rate":
      case "Team Change failure rate":
        return "Change failure rate (%)";
      case "Deployment rollback":
      case "Team Deployment rollback":
        return "Deployment rollback (Hours)";
      case "Mean Lead Time for Change":
        return "Mean Lead Time for Change (Hours)";
      case "Mean Time To Restore":
        return "Mean Time To Restore (Hours)";
      case "Cycle Time (Pull Request)":
      case "Team Cycle Time (Pull Request)":
        return "Cycle Time (Pull Request) (Min)";
      case "Total time spent on interruptions":
        return "Total time spent on interruptions (Min)";
      case "Total time spent on deep work":
        return "Total time spent on deep work (Min)";
      case "Total time spent on meeting":
        return "Total time spent on meeting (Min)";
      case "Total time spent on fragments":
        return "Total time spent on fragments (Min)";
      case "Speeding Transitions Rate":
      case "Team Speeding Transitions Rate":
      case "Resource Speeding Transitions Rate":
        return "Speeding Transitions Rate (%)";
      case "Commit without pull request":
      case "Team Commit without pull request":
      case "Resource Commit without pull request":
        return "Commit without pull request (%)";
      case "Ticket Without Epics/Stories":
      case "Team Ticket Without Epics/Stories":
      case "Resource Ticket Without Epics/Stories":
        return "Ticket Without Epics/Stories (Count)";
      case "Ticket Work Allocation":
      case "Team Ticket Work Allocation":
      case "Resource Ticket Work Allocation":
        return "Count";
      case "Dev Attention Time":
      case "Team Dev Attention Time":
      case "Resource Dev Attention Time":
        return "Total Time Elapsed (Hrs)";
      case "Ticket Without Estimation":
      case "Team Ticket Without Estimation":
      case "Resource Ticket Without Estimation":
        return "Count";
      default:
        break;
    }
  };

  const yAxesLabel = yAxesLabelText(issueType);

  return (
    <div ref={myDivRef}>
      <Card className="customCard" style={{ marginTop: "30px", width: "100%" }}>
        <h4 className="p-3">{issueType} trend</h4>
        <div
          style={{
            width: "300px",
            paddingLeft: 20,
            paddingTop: 20,
            display: "flex",
            gap: 10,
            width: 500,
          }}
        >
          <NormalSelect
            option={[
              { label: "Monthly", value: 30 },
              { label: "Biweekly", value: 14 },
              { label: "Weekly", value: 7 },
              { label: "Daily", value: 1 },
            ]}
            value={iteration}
            handleChange={(value) => setIteration(value)}
          />
          {date && (
            <div>
              {/* Date drop down */}
              <SelectField
                input={
                  <Button
                    onClick={() => setOpenRangeTrand(true)}
                    style={{
                      border: "1px solid #b7b7b7",
                      width: "230px",
                      textTransform: "capitalize",
                      padding: "5px",
                      fontSize: "14px",
                      fontWeight: 400,
                      paddingLeft: "14px ",
                      paddingRight: "11px",
                      justifyContent: "space-between",
                      borderRadius: "25px",
                      height: "48px",
                    }}
                    endIcon={<DateRangeOutlinedIcon />}
                  >
                    {getSelectedDate(date)}
                  </Button>
                }
                onClick={() => setOpenRangeTrand(true)}
              />
              <RangeSelector
                isNextDate={false}
                open={openRangeTrend}
                setOpen={setOpenRangeTrand}
                pickerHandler={(val) => setDate(val)}
                value={date}
              />
            </div>
          )}
        </div>
        {issueType === "Commit Without Ticket Reference" ||
        issueType === "Team Commit Without Ticket Reference" ||
        issueType === "Resource Commit Without Ticket Reference" ||
        issueType === "Commit with pull request" ||
        issueType === "Team Commit with pull request" ||
        issueType === "Resource Commit with pull request" ||
        issueType === "Average Flow Efficiency" ||
        issueType === "Team Average Flow Efficiency" ||
        issueType === "Resource Average Flow Efficiency" ||
        issueType === "Unreviewed PR" ||
        issueType === "Team Unreviewed PR" ||
        issueType === "Resource Unreviewed PR" ||
        issueType === "Pr Review Time" ||
        issueType === "Team Code Churn" ||
        issueType === "Resource Code Churn" ||
        issueType === "Change failure rate" ||
        issueType === "Deployment rollback" ||
        issueType === "Team Change failure rate" ||
        issueType === "Team Deployment rollback" ||
        issueType === "Mean Lead Time for Change" ||
        issueType === "Total time spent on interruptions" ||
        issueType === "Total time spent on fragments" ||
        issueType === "Total time spent on deep work" ||
        issueType === "Total time spent on meeting" ||
        issueType === "Speeding Transitions Rate" ||
        issueType === "Dev Attention Time" ||
        issueType === "Team Dev Attention Time" ||
        issueType === "Resource Dev Attention Time" ||
        issueType === "Ticket Without Estimation" ||
        issueType === "Team Ticket Without Estimation" ||
        issueType === "Resource Ticket Without Estimation" ||
        issueType === "Team Speeding Transitions Rate" ||
        issueType === "Resource Speeding Transitions Rate" ||
        issueType === "Team Pr Review Time" ||
        issueType === "Commit without pull request" ||
        issueType === "Team Commit without pull request" ||
        issueType === "Ticket Without Epics/Stories" ||
        issueType === "Team Ticket Without Epics/Stories" ||
        issueType === "Resource Ticket Without Epics/Stories" ||
        issueType === "Resource Commit without pull request" ? (
          <TrendBar
            graphData={data}
            yAxesLabel={yAxesLabel ? yAxesLabel : ""}
            issueType={issueType}
          />
        ) : (
          <StackedColumnChart
            graphData={data}
            yAxesLabel={yAxesLabel ? yAxesLabel : ""}
            height={issueType === "Planning Accuracy" ? "600px" : "800px"}
          />
        )}
      </Card>
    </div>
  );
}

export default Trend;
